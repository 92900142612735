@import '../scss/theme-color.scss';


.form-group {
	position: relative;

	label {
		display: block;
		font-weight: 300;
		margin-bottom: 0.375rem;
	}


	.form-control {
		color: rgba(51, 48, 60, 0.87);
		font-weight: 200;
		line-height: 1.5rem;
		padding: 10px 12px;
		text-shadow: 0 0 rgba(51, 48, 60, 0.38);

		&:focus {
			border: 0.0625rem solid $success;
			// box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
			box-shadow: none;
		}
	}

	.show-password {
		right: 0;
		position: absolute;
		top: 32px;
	}

	.react-dropdown {
		.react-select__control {
			border-color: $gainsboro;
			border-radius: $border-radius;

			.react-select__value-container {
				padding: 7px 12px;
				// text-transform: capitalize;

				.react-select__placeholder {
					color: rgba(51, 48, 60, 0.87);
					font-weight: 200;
					text-shadow: 0 0 rgba(51, 48, 60, 0.38);
				}
			}

			&.react-select__control--is-focused {
				box-shadow: none;
				border-color: $success;
			}
		}

		.react-select__menu {
			border-radius: $border-radius;
			border: 0;
			box-shadow: rgba(0, 0, 0, 0.1607843137) 0px 1px 4px;

			.react-select__menu-list {
				padding: 0;

				.react-select__option {
					font-size: 14px;
					padding: 10px 15px;

					&:first-child {
						border-top-right-radius: $border-radius;
						border-top-left-radius: $border-radius;
					}

					&:last-child {
						border-bottom-right-radius: $border-radius;
						border-bottom-left-radius: $border-radius;
					}

					&.react-select__option--is-selected {
						background-color: $success !important;
					}

					&.react-select__option--is-focused {
						background-color: $light;
					}
				}
			}
		}
	}

	.input-group {
		.input-group-text {
			background-color: $white;
			border-right: 0;
			padding-right: 0;
		}

		.form-control {
			border-left: 0;
			border-color: $gainsboro;
			height: 100%;
		}
	}

	&.phone-numer-field {
		.react-tel-input {
			.form-control {
				width: 100%;
				min-height: 44.5px;
				height: 44.5px;
				border-color: $gainsboro;
				border-radius: $border-radius;
				padding-left: 65px;
				font-size: 1rem;

				// &:focus {
				// 	border: 0.0625rem solid $success;
				// }
			}

			.flag-dropdown {
				background-color: $white;
				border-color: $gainsboro;
				border-radius: $border-radius 0 0 $border-radius;

				.selected-flag {
					width: 50px;

					.arrow {
						left: 28px;
					}
				}
			}
		}

		&.invalid {

			.form-control,
			.flag-dropdown {
				border-color: $danger;
				// color: $danger;
			}
		}
	}

	&.date-field {
		.react-datepicker-wrapper {
			width: 100%;
		}
	}

	&.invalid {

		.form-control,
		.react-select__control {
			border-color: $danger;
			color: $danger;
		}
	}

	.form-check {
		.form-check-input {
			width: 18px;
			height: 18px;

			&:checked {
				background-color: $success;
				border-color: $success;
			}

			&:focus {
				box-shadow: 0 0 0 0.25rem rgba(43, 127, 117, 0.25);
			}
		}
	}

	.form-check-label {
		padding-left: 8px;
		padding-top: 2px;
	}
}