.thankyou-card {
	justify-content: center;
	align-items: center;
	display: flex;
	height: 100vh;
	margin-left: auto;
	margin-right: auto;

	.card {
		padding: 20px;
		// background-color: #F9FAFB;
		border: 0px;
		box-shadow: 3.67px 4.89px 52.91px 0px rgba(0, 0, 0, 0.1215686275);
		border-radius: 13px;

		.card-header {
			.brand-logo {
				a {
					margin-bottom: 20px;
				}
			}

			h6 {
				margin: 20px 0px;
			}

			p {
				// font-size: 18px;
				margin: 20px 0px;

				span {
					// background-color: #2B7F75;
					// padding: 5px 15px;
					// border-radius: 20px;
					// color: #fff;
					// margin-right: 10px;
					font-weight: 600;
				}
			}
		}

		.card-body {
			.service-card {
				p {
					// font-size: 18px;
					margin-bottom: 10px;
					color: #818181;
				}

				h6 {
					font-size: 16px;
					line-height: 15px;
				}
			}
		}

		.card-footer {
			text-align: center;

			.calendar-logo-list {
				p {
					margin-bottom: 25px;
					margin-top: 20px;
				}

				.btn-outline-success {
					padding: 8px 20px;
					font-size: 13px;
				}
			}


		}
	}
}