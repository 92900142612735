@import "../../../../assets/scss/theme-color.scss";



.email-notifications {
	padding-top: 20px;

	.card {
		.card-body {
			// height: calc(100vh - 227px);
			overflow-x: hidden;
			overflow-y: auto;
		}
	}

	.template-vars {
		// height: calc(100vh - 193px);
		overflow-x: hidden;
		overflow-y: auto;

		.btn-outline-success {
			padding: 4px 14px;
			font-size: 13px;
			// text-transform: capitalize;
		}
	}

	a {
		font-size: 10px;

		.edit-button {
			height: 12px;
			width: 10px;
			margin-right: 6px;
		}
	}

	.field-container {
		margin-bottom: 20px;
	}

	.table-advance {
		margin-bottom: 30px;
	}

	.accordion-item {
		border: 0px;
		border-radius: 0px;

		&:first-of-type {
			border-radius: 0px;
		}

		.accordion-button:not(.collapsed) {
			color: #000;
			background-color: transparent;
			box-shadow: none;
		}

		.accordion-button {
			color: #000;
			background-color: transparent;
			box-shadow: none;
		}
	}

}