@import "../../../../assets/scss/theme-color.scss";

.schedules-content {
	// background-color: red;

	.nav-pills {
		border-bottom: 1px solid #79797980;
		margin-top: -1px;

		.nav-item {
			margin-right: 20px;

			.nav-link {
				border-radius: 0px;
				color: #1a1a1a9c;

				&:hover {
					color: $success;
				}
			}

			.active {
				background-color: transparent;
				border-bottom: 2px solid$success;
				color: $success;
			}
		}
	}

	.normal-day-row {
		margin-top: 20px;

		.card {
			.button-height {
				height: 50px;
			}

			padding: 20px;

			.card-body {
				// height: calc(100vh - 336px);
				overflow-x: hidden;
				overflow-y: auto;
				padding-top: 20px;
			}
		}

		.weekly-hours-row {
			border: 1px solid #ebebeb;
			padding: 10px;
			margin: 1px;
			box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

			.form-group {
				margin-bottom: 0px;
			}
		}

		.breaktimes-row {
			background-color: #fff8ea;
			padding: 15px 0;
		}



		.weekly-hours-button {
			background-color: $white;
			border: 1px solid $success;
			color: $success;
			font-size: 13px;

			// &:hover {
			// 	background-color: $success;
			// 	color: $white;
			// }
		}

		.danger-button {
			background-color: $white;
			border: 1px solid $danger;
			color: $danger;
			font-size: 13px;

			// &:hover {
			// 	background-color: $success;
			// 	color: $white;
			// }
		}

		.spinner-border {
			--bs-spinner-width: 23px;
			--bs-spinner-height: 23px;
		}
	}
}