.users-card-row {
	justify-content: center;
	align-items: center;
	display: flex;
	height: 100vh;
	margin-left: auto;
	margin-right: auto;

	.card {
		padding: 25px;
		border: 0px;
		box-shadow: 3.67px 4.89px 52.91px 0px rgba(0, 0, 0, 0.1215686275);
		border-radius: 13px;

		.card-header {
			img {
				width: 160px;
				height: 41px;
				max-width: 100%;
				max-height: 100%;
				margin-bottom: 20px;
			}
		}

		.users-card {

			.card-body {
				.form-group {
					margin-bottom: 0px;
				}
			}
		}
	}

	.user-arrow-button {
		.btn-success {
			width: 45px;
			height: 42px;
			margin-top: 0px;
			padding: 0px;
			align-items: center;
			display: flex;
			justify-content: center;
		}
	}

	.user-time-list {
		border-bottom: 1px solid #dee2e6;

		.btn-check:checked+.btn {
			color: $success;
			background-color: transparent;
			border: 2px solid $success;
		}

		.btn-check {
			padding: 18px;
			border-radius: 5px;
			box-shadow: none;
			border: 1px solid rgba(221, 221, 221, 0.8666666667);
			justify-content: center;
			margin-bottom: 7px;
			text-align: center;

			&:hover {
				border: 2px solid $success;
			}
		}

		.btn-check+.btn:hover {
			color: $success;
			background-color: var(--bs-btn-bg);
			border-color: $success;
		}

		.btn-outline-secondary {
			height: 78px;
			width: 84px;
			border: 2px solid rgba(221, 221, 221, 0.8666666667);
			color: #000;
			align-items: center;
			flex-direction: column;
			display: flex;
			justify-content: center;

			p {
				margin-bottom: 7px;
				font-size: 16px;
			}

			h6 {
				font-weight: 600;
				font-size: 20px;
			}
		}
	}

	.min-meeting-content {
		margin-bottom: 10px;

		p {
			margin-bottom: 0px;
		}
	}

	.slod-button-group {
		ul {
			display: flex;
			overflow: auto;

			li {
				margin-bottom: 0px;

				.btn-outline-success {
					// font-weight: 600;
					height: 89px;
					width: 120px;
					border: 2px solid rgba(221, 221, 221, 0.8666666667);
					align-items: center;
					flex-direction: column;
					display: flex;
					justify-content: center;
					padding: 11px;
					color: #000;

					&:hover {
						border-color: $success;
						background: transparent;
						color: $success;

					}

					&:focus {
						border-color: $success;
						background-color: transparent;

					}

					span {
						display: block;
					}

				}

				.btn-check:checked+.btn,
				:not(.btn-check)+.btn:active,
				.btn:first-child:active,
				.btn.active,
				.btn.show {
					border-color: #198754;
					background: transparent !important;
					color: #198754;
					border-color: #198754 !important;
				}
			}
		}
	}

	.users-inner-form {
		padding-top: 50px;
	}
}

.user-form-group {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.user-placeholder-label {
		min-height: 12px;
		width: 45px;
	}

	.user-placeholder-control {
		min-height: 50px;
		width: 280px;
		border-radius: 8px;

	}

	.arrow-button {
		min-height: 45px;
		width: 55px;
		border-radius: 5px;
	}

}

.user-time-list-placeholder {
	.list-inline-item {
		min-height: 84px;
		width: 84px;
		border-radius: 8px;
		margin-bottom: 10px;
	}
}

.user-form-meeting {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.user-placeholder-label {
		min-height: 12px;
		width: 45px;
	}

	.user-placeholder-control {
		min-height: 50px;
		width: 280px;
		border-radius: 8px;

	}
}

.user-time-list-group {
	.list-inline-item {
		min-height: 80px;
		width: 100px;
		border-radius: 6px;
		margin-bottom: 10px;
	}
}

.user-form-meeting {
	.meeting-label {
		min-height: 18px;
		width: 100px;
	}

	.meeting-icon {
		width: 20px;
		min-height: 20px;
		border-radius: 20px;

	}


}