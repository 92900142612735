@import 'bootstrap/scss/bootstrap.scss';
@import '../scss/button-loading.scss';
@import '../scss/form-control.scss';
@import './theme-color.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
@import '../../views/stories/Form-controls/Button/style.scss';

html,
body {
	margin: 0;
	padding: 0;
	font-family: 'Poppins', sans-serif;
	font-size: 15px;
	font-weight: 400;
	background-color: #f9fafb;
}

a {
	text-decoration: none;
	color: $dark;
}

a:hover {
	color: $success;
}

h1 {
	font-size: 50px;
	line-height: 80px;
}

h2 {
	font-size: 40px;
	line-height: 30px;
}

h3 {
	font-size: 30px;
	line-height: 34px;
}

h4 {
	font-size: 24px;
	line-height: 130%;
}

h5 {
	font-size: 20px;
	line-height: 130%;
}

h6 {
	font-size: 18px;
	line-height: 20px;
	font-weight: 500;
}

.pb-10 {
	padding-bottom: 10px;
}

.space-between {
	justify-content: space-between;
	display: flex;
}

.justify-center {
	justify-content: center;
	display: flex;
}

.align-middles {
	align-items: center;
	display: flex;
}

table {
	&.table {
		margin-bottom: 0;

		tr {
			vertical-align: middle;
			height: 55px;

			th,
			td {
				&:first-child {
					padding-left: 20px;

				}

				&:last-child {
					padding-right: 20px;
					text-align: end;
				}
			}
		}

		thead {
			tr {
				th {
					font-weight: 600;

					&.status-field {
						.btn {
							border: 0px;
							padding: 0px;
						}

						.dropdown-menu {
							padding: 0px;
							border-radius: 0px;
							padding: 0px;
							border-radius: $border-radius;
							max-width: 200px;
							width: 100%;
							box-shadow: rgba(0, 0, 0, 0.1607843137) 0px 1px 4px;
							border: 0;

							.dropdown-item {
								font-size: 14px;

								&:hover {
									background-color: $success;
									color: $white;
								}
							}
						}
					}

					&.action-button {
						width: 180px;
					}
				}
			}

		}

		tbody {
			tr {
				td {



					ul {
						&.list-inline {
							margin-bottom: 0;
						}

						li {
							&:not(:last-child) {
								margin-right: 7px;
							}
						}
					}

					img,
					svg {
						height: 15px;
						object-fit: scale-down;
						width: 15px;
					}
				}
			}

			tr:first-child td:first-child {
				border-top-left-radius: 10px;
			}

			tr:first-child td:last-child {
				border-top-right-radius: 10px;
			}

			tr:last-child td:first-child {
				border-bottom-left-radius: 10px;
			}

			tr:last-child td:last-child {
				border-bottom-right-radius: 10px;
			}
		}
	}
}

.card {
	.card-header {
		border-color: $gainsboro;
		background-color: $white;

		.form-group {
			margin-bottom: 0 !important;
		}

		ul {
			&.list-inline {
				margin-bottom: 0;

				li {
					&:not(:last-child) {
						margin-right: 15px;
					}

					img,
					svg {
						height: 1.25rem;
						object-fit: scale-down;
						width: 1.25rem;
					}
				}
			}
		}
	}

	.card-body {
		&.table-responsive {
			padding: 0;
		}
	}

	.card-footer {
		border-top: 0;
		background-color: $white;
	}

}

.modal {
	.modal-dialog {
		.modal-content {

			.modal-header {
				background-color: #f9fafb;
			}

			.modal-footer {
				background-color: #f9fafb;

				.btn {
					margin: 0;
					padding: 7px 25px 8px;
				}
			}
		}
	}
}

.form-switch {
	padding-left: 1.5em;

	.form-check-label {
		padding-top: 3px;
	}

	.form-check-input {
		width: 40px;
		height: 20px;
		margin-left: -51px;

		&:checked {
			background-color: $success;
			border-color: $success;
		}

		&:focus {
			box-shadow: 0 0 0 0.25rem rgba(43, 127, 117, 0.25);
		}
	}

}

.eye-button {
	background-color: $success;
	width: 33px;
	height: 33px;
	display: inline-block;
	text-align: center;
	border-radius: 10px;
	color: $white;
	align-items: center;
	display: flex;
	justify-content: center;

	&:hover {
		color: $white;
	}
}

.eye-edit {
	background-color: $primary;
	width: 33px;
	height: 33px;
	display: inline-block;
	text-align: center;
	border-radius: 10px;
	color: $white;
	padding: 0px;
	align-items: center;
	display: flex;
	justify-content: center;

	&:hover {
		color: $white;
		background-color: $primary;
	}
}

.eye-delete {
	background-color: $danger;
	width: 33px;
	height: 33px;
	display: inline-block;
	text-align: center;
	border-radius: 10px;
	color: $white;
	padding: 0px;
	align-items: center;
	display: flex;
	justify-content: center;

	&:hover {
		color: $white;
		background-color: $danger;

	}
}