@import '../../../assets/scss/theme-color.scss';

footer {
	background-color: $white;
	padding: 19px 0px;
	border-top: 2px solid #E6E9EB;
	position: relative;
	z-index: 3;

	.copyright-area {
		p {
			margin-bottom: 0px;

			a {
				color: $success;
			}
		}
	}

}