.change-passwrod-section {
	height: 100%;

	.row {
		justify-content: center;

		.brand-logo {
			h4 {
				margin-top: 15px;
			}
		}

		.card {

			.card-header {
				padding: 10px 20px;

				h5 {
					margin-bottom: 0px;
				}
			}

			.card-body {
				padding: 10px 20px;
				padding-bottom: 0px;
			}
		}
	}

}