@import '../../../assets/scss/theme-color.scss';

.sidebar-colums {
	max-width: 250px;
	width: 100%;
	height: 100vh;
	background-color: $white;
	border-right: 2px solid $antiflashwhite;
	padding-top: 15px;
	float: left;
	position: fixed;
	left: 0;
	display: flex;
	flex-direction: column;

	.sidebar-inner {
		overflow-y: auto;
		margin-bottom: auto;
	}

	.main-logo {
		margin-bottom: 30px;
		padding-left: 12px;
	}

	ul {
		margin-bottom: 15px;

		h6 {
			font-size: 12px;
			font-weight: 400;
			padding-left: 14px;
			margin-bottom: 0;
			color: $taupegray;
		}

		li {
			margin-bottom: 10px;

			a {
				font-weight: 500;
				font-size: 14px;
				display: block;
				padding: 12px;
				color: $black;

				span {
					margin-right: 10px;
				}
			}

			.active {
				background-color: $light;
				border-right: 2px solid $success;
				color: $success;
			}
		}
	}

	.logout-col {
		.btn {
			font-weight: 500;
			padding: 20px 12px;
			font-size: 14px;
			display: block;
			width: 100%;
			text-align: start;
			border: 0;
			border-top: 2px solid $gainsboro;
			border-radius: 0;

			&:active {
				border-color: $gainsboro;
			}

			span {
				margin-right: 10px;
			}
		}
	}
}