@import '../../../assets/scss/theme-color.scss';

.auth-form {
	align-items: center;
	justify-content: center;
	height: 100vh;
	margin-left: auto;
	margin-right: auto;

	@media only screen and (max-width: 991px) {
		height: auto;
	}

	// .login-form-col {
	// 	padding: 0px 30px;

	// 	@media only screen and (max-width: 1440px) {
	// 		padding: 0px;
	// 	}

	// }

	.card {
		padding: 50px;
		border: 0px;
		// width: 550px;
		box-shadow: 3.67px 4.89px 52.91px 0px #0000001F;
		border-radius: 30px;

		@media only screen and (max-width: 1366px) {
			padding: 25px;
		}

		@media only screen and (max-width: 991px) {
			padding: 30px;
			border-radius: 0px;
		}

		.brand-logo {
			margin-bottom: 20px;

			h4 {
				margin-bottom: 8px;
			}

			img {
				width: 160px;
				height: 41px;
				max-width: 100%;
				max-height: 100%;
				margin-bottom: 40px;

				@media only screen and (max-width: 991px) {
					margin-bottom: 20px;

				}
			}
		}

		.form-group {
			position: relative;
		}

		.btn-success {
			width: 100%;
			margin-top: 30px;
		}

		.btn-link {
			color: $success;
			margin-top: auto;
			margin-bottom: auto;
			padding: 0px;
			text-decoration: none;

			@media only screen and (max-width: 550px) {
				display: flex;
				justify-content: center;
			}
		}

		.forgot-password-link {
			display: flex;
			align-items: center;
			justify-content: space-between;

			@media only screen and (max-width: 550px) {
				display: block;
			}

			.form-group {
				margin-bottom: 0px;
			}
		}

		.hand-moblie-col {
			text-align: end;

			@media only screen and (max-width: 1440px) {
				text-align: end;
			}

			@media only screen and (max-width: 991px) {
				margin-top: 20px;
			}

			img {
				width: 500px;
				height: auto;

				@media only screen and (max-width: 1440px) {
					width: 90%;
				}

				@media only screen and (max-width: 1366px) {
					width: 100%;
				}
			}
		}
	}

	p {
		margin-bottom: 0px;
	}

	.login-link-button {
		text-align: center;
		margin-top: 15px;

		.btn-link {
			padding: 0px;
			text-decoration: none;
		}
	}

	.login-password-field {
		position: relative;

		.btn-link {
			position: absolute;
			top: -4px;
			right: 0px;
		}
	}

	.verification-form {
		.brand-logo {
			p {
				span {
					color: $success;
				}
			}

		}

		.security-code {
			.form-control {
				height: 40px !important;
				width: 42px !important;

			}
		}

		.get-the-code {
			align-items: center;
			display: flex;
		}

		.spinner-border {
			--bs-spinner-width: 18px;
			--bs-spinner-height: 18px;
			--bs-spinner-border-width: 0.15em;
		}
	}
}