@import '../../../../assets/scss/theme-color.scss';

.previous-arrow,
.next-arrow {
	font-size: 0 !important;

	&::before {
		content: "";
		background: url(../React-Pagination/icons/arrow-left.svg) no-repeat;
		background-size: contain;
		height: 10px;
		width: 13px;
		display: inline-block;
		vertical-align: middle;
	}

	&:hover {
		&::before {
			background: url(../React-Pagination/icons/arrow-left.svg) no-repeat;
			background-size: contain;
		}
	}
}

.next-arrow {
	&::before {
		transform: rotate(180deg);
	}
}

.pagination-wrap {
	display: flex;

	.pagination {
		margin-bottom: 0;

		.page-item {
			&.active {

				.page-link {
					background-color: $success;
					color: #fff;
				}
			}

			&:not(:last-child) {
				margin-right: 0.5rem;
			}

			.page-link {
				border: 0;
				height: 1.875rem;
				width: 1.875rem;
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: 500;
				font-size: 0.75rem;
				line-height: 0.875rem;
				color: $black;
				border-radius: $border-radius;

				&:hover {
					background-color: $success;
					color: $white;
				}

				&:focus {
					box-shadow: none;
					background-color: $success;
				}
			}
		}
	}
}