@import '../../../assets/scss/theme-color.scss';


.main-content {
	max-width: calc(100% - 250px);
	width: 100%;
	float: right;
	height: 100%;
	transition: all .8s ease;
	padding: 0px;

	.main-wrapper {
		margin: 0px 40px;
		padding: 20px 0px 10px;
		height: calc(100vh - 130px);

		.new-appoinment {
			padding: 10px 0px;
		}

		.card {
			background-color: $white;
			box-shadow: #00000029 0px 1px 4px;
			border-radius: 5px;
			border: 0px;

			.card-header {
				position: relative;

				.filter-dropdow-list {
					position: inherit;

					.btn-link {
						border: 1px solid $eerieblack !important;
						padding: 11px 20px;
						color: $secondary;
						text-decoration: none;
						box-shadow: none;
					}

					.modal-body {
						height: calc(100vh - 490px);
						overflow-x: hidden;
						overflow-y: auto;
						min-height: 400px;

						.accordion-item {
							border: 0px;
							background-color: transparent;
						}

						.accordion-button:not(.collapsed) {
							background-color: transparent;
							box-shadow: none;
						}

						.accordion-button {
							&:focus {
								box-shadow: none;
							}
						}
					}

					// .react-datepicker__day--today {
					// 	background-color: $success !important;
					// 	color: white;
					// 	border-radius: 4px;
					// }

					.react-datepicker__day--outside-month,
					.react-datepicker__day {
						&:hover {
							background-color: $success !important;
							color: white;
						}
					}

					.react-datepicker__day--selected {
						// background-color: $success !important;
						// color: white;
						background-color: $success !important;
						color: white;
						border-radius: 4px;
					}
				}
			}

			.data-entry {
				width: 100px;

				.form-select {
					box-shadow: none;
					border-radius: $border-radius;
					font-size: 14px;
					height: 44px;
					border: 1px solid $eerieblack !important;
					padding: 10px;
				}
			}

			.complated-text {
				background: $mintcream;
				color: $success;
				font-weight: 600;
				padding: 4px 20px;
				border-radius: 4px;
				font-size: 14px;
				width: 120px;
				text-align: center;
				display: block;
			}

			.today-text {
				background: $mintcream;
				color: $mustardyellow;
				font-weight: 600;
				padding: 4px 20px;
				border-radius: 4px;
				font-size: 14px;
				width: 120px;
				text-align: center;
				display: block;
			}

			.progress-text {
				background: $coolmint;
				color: $primary;
				font-weight: 600;
				padding: 4px 20px;
				border-radius: 4px;
				font-size: 14px;
				width: 120px;
				text-align: center;
				display: block;
			}
		}

		.arrow-up-down {
			transform: rotate(90deg);
			margin-left: 3px;
		}

		.booking-tab-list {
			li {
				.btn {
					border: 0px;
					padding: 0px;
				}
			}
		}
	}
}




.dashboard-section {

	// height: calc(100vh - 160px);
	// overflow-x: hidden;
	// overflow-y: auto;

	.chart-card {

		.card {
			background-color: #fff;
			box-shadow: rgba(0, 0, 0, 0.1607843137) 0px 1px 4px;
			border-radius: 5px;
			border: 0px;
			padding: 15px;

			p {
				margin-bottom: 5px;
			}

			h4 {
				font-weight: 600;
			}

			a {
				color: #000;

				span {
					color: $success;
					margin-right: 5px;
				}
			}

		}


	}

	.patient-visited {
		margin-top: 20px;

		.card-header {
			display: flex;
			justify-content: space-between;
			align-items: center;

			h5 {
				margin-bottom: 0px;
			}

			.nav-pills {
				margin-bottom: 0px;

				.nav-item {
					.nav-link {
						color: #000;
					}

					.active {
						background-color: $success;
						color: $white;
					}
				}
			}
		}

		.card {

			.card-body {
				min-height: 400px;

				img {
					object-fit: cover;
					padding: 8px;
					// width: 100%;
				}
			}
		}
	}

	.appointment-table {
		margin-top: 20px;

		.eye-edit {
			&:hover {
				background-color: $primary;
			}
		}

		.btn-link {
			padding: 0px;
			color: $success;
			text-decoration: none;
		}
	}
}