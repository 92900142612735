@import "../../../../assets/scss/theme-color.scss";

.booking-section {
	.card {
		// .card-body {
		// 	height: calc(100vh - 276px);
		// 	overflow-x: hidden;
		// 	overflow-y: auto;
		// }
	}

	// .react-datepicker__day--today {
	// 	background-color: $success !important;
	// 	color: white;
	// 	border-radius: 4px;

	// }

	.react-datepicker__day--outside-month,
	.react-datepicker__day {
		&:hover {
			background-color: $success !important;
			color: white;
		}
	}

	.react-datepicker__day--selected {
		// 	background-color: $success !important;
		// 	color: white;
		background-color: $success !important;
		color: white;
		border-radius: 4px;
	}

	.react-datepicker-popper {
		inset: inherit !important;
		transform: none !important;
	}

}

.booking-step-1 {
	// .react-datepicker__day--today {
	// 	background-color: $success !important;
	// 	color: white;
	// 	border-radius: 4px;

	// }

	.react-datepicker__day--outside-month,
	.react-datepicker__day {
		&:hover {
			background-color: $success !important;
			color: white;
		}
	}

	.react-datepicker__day--selected {
		// 	background-color: $success !important;
		// 	color: white;
		background-color: $success !important;
		color: white;
		border-radius: 4px;
	}





	.services-step-active {
		// .btn-success {
		// 	padding: 5px 24px;
		// }

		.btn-outline-success {
			width: 204px;

		}
	}

	.booking-slots {
		// height: calc(100vh - 550px);
		max-height: 345px;
		overflow-x: hidden;
		overflow-y: auto;

		.btn-outline-success {
			width: 180px;
			padding: 8px 16px;
			font-size: 14px;
		}
	}

	.service-placehoders {
		.service-list {
			min-height: 40px;
			width: 204px;
			border-radius: 5px;
			margin-bottom: 9px;
		}
	}

	.calendar-placeholder {
		border: 1px solid #aeaeae;
		padding: 12px;
		border-radius: 5px;

		.calendar-list {
			.list-inline-item {
				min-height: 18px;
				width: 18px;
				border-radius: 4px;
				margin-right: 12px;
				margin-bottom: 8px;

				&:last-child {
					margin-right: 0px;

				}
			}
		}
	}
}

.booking-step-2 {}

.booking-step-3 {
	p {
		margin-bottom: 8px;
	}
}

.react-datepicker {
	// margin-top: 44px;
}

.to-do-list-section {
	height: calc(100vh - 292px);
	overflow-x: hidden;
	overflow-y: auto;

	.fc-daygrid-dot-event {
		&:hover {
			background-color: transparent;
		}

	}

	.fc-toolbar-title {
		font-size: 20px;
		line-height: 130%;
	}

	.fc-today-button {
		text-transform: capitalize;
	}

	.timing-button {
		// position: absolute;
		// top: 0;
		// margin: auto;
		// left: 0;
		// right: 0;
		// bottom: 0;
		// height: 45px;
		// width: 200px;
		z-index: 1;
	}

	.modal-footer {
		gap: 10px;

		.btn-success {
			padding: 5px 25px;
		}

		.btn-danger {
			padding: 9px 25px !important;
		}
	}
}