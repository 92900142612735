@import "../../../../assets/scss/theme-color.scss";

.btn-success {
	padding: 8px 25px;
	background: $success;
	color: $white;
	line-height: 25.2px;
	font-size: 15px;
	font-weight: 400;
	border-radius: 5px;
	transition: all .15s ease;
	border-color: $success;

	&:hover {
		background: #2b6c65;
		color: $white;
		border-color: #2b6c65;
	}
}

.btn-outline-success {
	padding: 8px 25px;
	color: $success;
	font-size: 15px;
	font-weight: 400;
	border-radius: 5px;
	transition: all .15s ease;
	border: 1px solid $success;

	&:hover {
		color: $white;
		border-color: #2b6c65;
		background: #2b6c65;
	}
}

.btn.btn-primary {
	padding: 8px 25px;
	background: $primary;
	color: $white;
	line-height: 25.2px;
	font-size: 15px;
	font-weight: 400;
	border-radius: 5px;
	transition: all .15s ease;
	border-color: $primary;

	&:hover {
		background: $primary;
		color: $white;
		border-color: $primary;
	}
}

.btn-danger {
	padding: 8px 25px;
	color: $white;
	font-size: 15px;
	font-weight: 400;
	border-radius: 5px;
	transition: all .15s ease;
	border: 1px solid $danger;

	&:hover {
		color: $white;
		border-color: $danger;
		background: $danger;
	}
}

.btn-outline-danger {
	padding: 8px 25px;
	color: $danger;
	font-size: 15px;
	font-weight: 400;
	border-radius: 5px;
	transition: all .15s ease;
	border: 1px solid $danger;
	background-color: $white;

	&:hover {
		color: $white;
		border-color: $danger;
		background: $danger;
	}
}