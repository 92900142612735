@import "../../../../assets/scss/theme-color.scss";


.profile-section {
	height: 100%;

	.row {
		justify-content: center;

		.card {
			// padding: 30px;

			.card-header {
				padding: 10px 20px;

				h5 {
					margin-bottom: 0px;
				}
			}

			.card-body {
				padding: 10px 20px;
				padding-bottom: 0px;

			}
		}
	}


}